import { useRouter } from "next/router";
import { useEffect } from "react";
import { useUpdateOrgInfo } from "~/stores/org-info";
import { api } from "~/utils/api";

function useRouteGuard() {
  const ctx = api.useContext();

  const router = useRouter();
  const pathname = router.pathname;

  const orgId = (router.query.orgId ?? "") as string;
  const projectId = (router.query.projectId ?? "") as string;

  const hasOrgInUrl = pathname.includes("/org/");
  const hasProjectInUrl = pathname.includes("/project/");

  const updateOrgInfo = useUpdateOrgInfo();

  const { data: orgInfo, error: errorOrgInfo } =
    api.organisation.getInfo.useQuery({ orgId }, { enabled: !!orgId });

  // console.log("orgInfo", orgInfo);

  useEffect(() => {
    if (hasOrgInUrl) {
      if (!!orgInfo) {
        updateOrgInfo(orgInfo);
      } else {
        updateOrgInfo(null);
      }
    } else {
      updateOrgInfo(null);
    }
  }, [orgInfo, hasOrgInUrl, errorOrgInfo, updateOrgInfo, router]);

  return {
    errorMsg: errorOrgInfo?.message, // does not throw error for inactive org.
  };
}

export default useRouteGuard;
