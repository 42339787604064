import React from "react";
import { Button } from "./ui/button";
import { useRouter } from "next/router";
import { ArrowLeftCircleIcon } from "lucide-react";
import { cn } from "~/lib/utils";

export default function BackToPreviousButton({
  href,
  className,
}: {
  href?: string;
  className?: string;
}) {
  const router = useRouter();
  // Check if router have no back in history, return null
  if (!router || !router.back) return null;
  // Check window history length, if it's 1, return null
  if (typeof window !== "undefined" && window.history.length <= 1) return null;
  return (
    // <div className="flex items-center justify-between">
    <div className={cn("flex items-center space-x-2", className)}>
      <Button
        onClick={() => (href ? void router.push(href) : void router.back())}
        variant="link"
        className="hover:text-foreground-hover flex items-center justify-start space-x-2 pl-0 font-normal text-muted-foreground"
      >
        <ArrowLeftCircleIcon className="h-4 w-4" />
        <span>Go back</span>
      </Button>
    </div>
    // </div>
  );
}
