import React from "react";
import HeadMeta from "../head-meta";

export default function BaseLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  // const { systemTheme } = useTheme();
  return (
    <>
      <HeadMeta>
        {/* <link
          rel="icon"
          href={systemTheme === "dark" ? "/logo_dark.svg" : "/logo.svg"}
        /> */}
      </HeadMeta>
      <main className="bg-background text-foreground flex min-h-screen flex-col items-center justify-center">
        {children}
      </main>
    </>
  );
}
