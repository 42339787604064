import React, { useEffect } from "react";

import { Avatar, AvatarFallback } from "~/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { Sheet, SheetContent, SheetTrigger } from "~/components/ui/sheet";

import { MenuIcon } from "lucide-react";
import { signIn, signOut, useSession } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";
import HeadMeta from "../head-meta";
import Logo from "../logo";
import { ModeToggle } from "../mode-toggle";
import { Button } from "../ui/button";
import BaseLayout from "./base";
import { HELP_CENTER_SUBMIT_REQUEST } from "~/constants/help-articles";
import { cn } from "~/lib/utils";
import Banner from "~/components/banner";

export default function DashboardLayout({
  children,
  fullWidth = false,
  title,
  mobileSidebar,
}: {
  children: React.ReactNode;
  fullWidth?: boolean;
  title?: string;
  mobileSidebar?: React.ReactNode;
}) {
  const { data: sessionData, status } = useSession();

  useEffect(
    function redirectToSigninOnSessionExpiry() {
      if (status === "unauthenticated") {
        void signIn("", {
          callbackUrl: window?.location?.href ?? `/dashboard`,
        });
      }
    },
    [status],
  );

  return (
    <>
      <BaseLayout>
        <HeadMeta title={title} />
        <Banner />
        <div className="relative flex min-h-screen w-full flex-col">
          <div className="supports-backdrop-blur:bg-background/60 sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur">
            <div className="flex h-16 items-center justify-between px-4">
              {mobileSidebar && (
                <div className="flex items-center lg:hidden">
                  <Sheet>
                    <SheetTrigger>
                      <MenuIcon className="h-6 w-6" />
                    </SheetTrigger>
                    <SheetContent className="p-0" side="left">
                      {mobileSidebar}
                    </SheetContent>
                  </Sheet>
                </div>
              )}
              <Link href="/dashboard">
                <Logo long className="hidden h-8 sm:flex" />
                {/* <Logo long className="h-8 lg:flex" /> */}
                <Logo className="h-8 sm:hidden" />
              </Link>
              {/* <MainNav className="hidden sm:flex mx-6" /> */}
              <div className="flex items-center space-x-4 lg:ml-auto">
                <ModeToggle />
                <UserNav />
              </div>
            </div>
          </div>
          <div className={cn(fullWidth ? "" : "relative sm:container", "grow")}>
            {children}
          </div>
        </div>
      </BaseLayout>
    </>
  );
}

function UserNav() {
  const { data: sessionData } = useSession();
  const router = useRouter();
  // const { theme } = useTheme();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            {/* <AvatarImage src={sessionData?.user?.image ?? ""} alt="User" /> */}
            <AvatarFallback>
              {sessionData?.user?.name?.charAt(0).toUpperCase() ?? "U"}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p
              className="truncate text-sm font-medium leading-none"
              title={sessionData?.user?.name ?? "User"}
            >
              {sessionData?.user?.name ?? "User"}
            </p>
            <p
              className="truncate text-xs leading-none text-muted-foreground"
              title={sessionData?.user?.email ?? "Email"}
            >
              {sessionData?.user?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {sessionData?.user?.role === "ADMIN" && (
            <Link href="/dashboard/admin">
              <DropdownMenuItem className="cursor-pointer">
                Admin
              </DropdownMenuItem>
            </Link>
          )}
          <Link href="/dashboard">
            <DropdownMenuItem className="cursor-pointer">
              Dashboard
            </DropdownMenuItem>
          </Link>
          {/* <DropdownMenuItem>Billing</DropdownMenuItem> */}
          <Link href="/account">
            <DropdownMenuItem className="cursor-pointer">
              Account Settings
            </DropdownMenuItem>
          </Link>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          {/* <DropdownMenuItem> */}
          <a
            href={HELP_CENTER_SUBMIT_REQUEST}
            target="_blank"
            // rel="noopener noreferrer"
          >
            Support & Feedback
          </a>
          {/* </DropdownMenuItem> */}
        </DropdownMenuItem>
        <DropdownMenuSeparator className="" />
        {/* <DropdownMenuLabel className="font-normal sm:hidden">
          <div className="flex items-center gap-x-2">
            <ModeToggle className="border-0 p-0" />
            <p className="text-sm font-medium capitalize leading-none">
              {theme} theme
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator className="sm:hidden" /> */}
        <DropdownMenuItem
          onClick={() => {
            void signOut({
              callbackUrl: window?.location?.href ?? `/dashboard`,
            });
            // void router.push("/");
          }}
          className="cursor-pointer"
        >
          Sign out
          {/* <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut> */}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

// function MainNav({ className, ...props }: React.HTMLAttributes<HTMLElement>) {
//   return (
//     <nav
//       className={cn("flex items-center space-x-4 lg:space-x-6", className)}
//       {...props}>
//       <Link
//         to="/examples/dashboard"
//         className="text-sm font-medium transition-colors hover:text-primary">
//         Overview
//       </Link>
//       <Link
//         to="/examples/dashboard"
//         className="text-sm font-medium text-muted-foreground transition-colors hover:text-primary">
//         Customers
//       </Link>
//       <Link
//         to="/examples/dashboard"
//         className="text-sm font-medium text-muted-foreground transition-colors hover:text-primary">
//         Products
//       </Link>
//       <Link
//         to="/examples/dashboard"
//         className="text-sm font-medium text-muted-foreground transition-colors hover:text-primary">
//         Settings
//       </Link>
//     </nav>
//   );
// }
