import type { Project } from "@prisma/client";
import { create } from "zustand";
import type { RouterOutputs } from "~/utils/api";

type StoreOrgObjectType = RouterOutputs["organisation"]["getInfo"];

interface GuardState {
  orgInfo: StoreOrgObjectType | null;
  project: Project | null;
  updateOrgInfo: (x: StoreOrgObjectType | null) => void;
  updateProject: (x: Project | null) => void;
}

const useGuardStore = create<GuardState>((set) => ({
  orgInfo: null,
  project: null,
  updateOrgInfo: (orgInfo) => set({ orgInfo }),
  updateProject: (project) => set({ project }),
}));

export const useUpdateOrgInfo = () =>
  useGuardStore((state) => state.updateOrgInfo);

export const useProject = () => useGuardStore((state) => state.project);

export const useUpdateProject = () =>
  useGuardStore((state) => state.updateProject);

export const useOrgInfo = () => useGuardStore((state) => state.orgInfo);
