import React, { useState } from "react";
import { cn } from "~/lib/utils";
import { useOrgInfo } from "~/stores/org-info";
import { Badge } from "./ui/badge";
import { ArrowRight, ChevronRight, X } from "lucide-react";
import { openZendeskWidget } from "./zendesk-widget";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";

const Banner = () => {
  const [show, setShow] = useState(true);
  const orgInfo = useOrgInfo();

  function dismiss() {
    setShow(false);
  }

  const getContent = () => {
    const isDemoOrg = orgInfo?.isDemoOrg;
    if (isDemoOrg) {
      return (
        <p>
          This is a demo organization with limited functionality. For more
          information{" "}
          <span
            onClick={openZendeskWidget}
            className="inline-flex cursor-pointer items-center gap-x-1 hover:underline"
          >
            contact support <ArrowRight className="h-3 w-4" />
          </span>
        </p>
      );
    }
    return null;
  };

  const content: string | JSX.Element | null = getContent();
  const simple = true;

  const hideBanner = !show || !content;
  if (hideBanner) return null;
  if (simple) {
    return (
      <div
        className={cn(
          "relative w-full overflow-hidden bg-secondary px-4 py-0.5 text-center text-sm",
        )}
      >
        {content}
        <button
          onClick={dismiss}
          className="absolute right-1 top-1 rounded-sm hover:bg-primary hover:text-primary-foreground"
        >
          <X className="h-4 w-4" />
        </button>
      </div>
    );
  }
  return (
    <div className="relative isolate flex w-full items-center gap-x-6 overflow-hidden  px-4 py-1 text-sm sm:px-3.5 sm:before:flex-1">
      <div
        className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 w-2/3 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className={cn(
            "aspect-[577/310] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30",
            // "w-[36.0625rem]"
          )}
          style={{
            clipPath:
              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
          }}
        ></div>
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 w-2/3 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          className={cn(
            "aspect-[577/310] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30",
            // "w-[36.0625rem]"
          )}
          style={{
            clipPath:
              "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
          }}
        ></div>
      </div>
      {content}
      <div className="flex flex-1 justify-end">
        <button
          type="button"
          className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
        >
          <span className="sr-only">Dismiss</span>
          <svg
            className="h-5 w-5 text-gray-900"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            data-slot="icon"
          >
            <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Banner;
