import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";
import { cn } from "~/lib/utils";
import DashboardLayout from "./layouts/dashboard-layout";
import BackToPreviousButton from "./back-to-previous";

export function ErrorAlert({
  message = "Something went wrong.",
  title = "Error",
  className,
}: {
  message?: string;
  title?: string;
  className?: string;
}) {
  return (
    <Alert variant="destructive" className={cn(className, "")}>
      <ExclamationTriangleIcon className="h-4 w-4" />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{message}</AlertDescription>
    </Alert>
  );
}

export function ErrorAlertPage({
  message = "Something went wrong.",
  title = "Error loading this page",
  className,
}: {
  message?: string;
  title?: string;
  className?: string;
}) {
  return (
    <DashboardLayout title="Error accessing this page">
      <div className="mx-auto my-12 max-w-3xl items-center justify-center">
        <BackToPreviousButton />
        <ErrorAlert
          message={message}
          title={title}
          className={cn(className, "")}
        />
      </div>
    </DashboardLayout>
  );
}
