import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { type AppType } from "next/app";
import { ThemeProvider } from "~/components/theme-provider";

import { api } from "~/utils/api";

import "~/styles/globals.css";
import "~/styles/tiptap.styles.scss";

import Head from "next/head";
import Script from "next/script";
import { Toaster } from "~/components/ui/sonner-toast";
import ZendeskWidget from "~/components/zendesk-widget";
import useRouteGuard from "~/hooks/use-route-guard";
import { ErrorAlertPage } from "~/components/error-alert";

const MyApp: AppType<{ session: Session | null }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  const isProduction = process.env.NODE_ENV === "production";
  return (
    <SessionProvider session={session}>
      <ThemeProvider
        attribute="class"
        defaultTheme="system"
        enableSystem
        disableTransitionOnChange
      >
        <Head>
          <title>EasySLR: Streamline Your Systematic Literature Review</title>
          <meta
            name="description"
            content="Save time and increase your productivity with EasySLR - the SaaS software solution designed for you and your team of researchers."
          />
          <meta
            name="keywords"
            content="Systematic Literature Review, SLR, Systematic Review, SR, Systematic Literature Review Software, SLR Software, Systematic Review Software, SR Software, Systematic Literature Review Tool, SLR Tool, Systematic Review Tool, SR Tool, Systematic Literature Review App, SLR App, Systematic Review App, SR App, Systematic Literature Review Application, SLR Application, Systematic Review Application, SR Application, Systematic Literature Review SaaS, SLR SaaS, Systematic Review SaaS, SR SaaS, Systematic Literature Review Software as a Service, SLR Software as a Service, Systematic Review Software as a Service, SR Software as a Service, Systematic Literature Review Software Solution, SLR Software Solution, Systematic Review Software Solution, SR Software Solution, Systematic Literature Review Software Platform, SLR Software Platform, Systematic Review Software Platform, SR Software Platform"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta name="robots" content="index, follow" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/favicons/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicons/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicons/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link
            rel="mask-icon"
            href="/favicons/safari-pinned-tab.svg"
            color="#5bbad5"
          />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff"></meta>
        </Head>
        {isProduction && (
          <>
            <Script
              strategy="lazyOnload"
              src="https://www.googletagmanager.com/gtag/js?id=G-QB00B8DHLT"
            />
            <Script strategy="lazyOnload" id="google-analytics">
              {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
 
          gtag('config', 'G-QB00B8DHLT');
        `}
            </Script>
          </>
        )}
        <ComponentWrapper pageProps={pageProps}>
          <Component {...pageProps} />
        </ComponentWrapper>
        <Toaster />
        <ZendeskWidget />
      </ThemeProvider>
    </SessionProvider>
  );
};

export default api.withTRPC(MyApp);

function ComponentWrapper({
  children,
  pageProps,
}: {
  children: React.ReactNode;
  pageProps: unknown;
}) {
  const { errorMsg } = useRouteGuard();
  if (errorMsg) {
    return (
      <ErrorAlertPage title="Error loading organisation" message={errorMsg} />
    );
  }
  return <>{children}</>;
}
