export const HELP_CENTER = "https://support.easyslr.com/hc/en-us";

export const HELP_CENTER_SUBMIT_REQUEST = `${HELP_CENTER}/requests/new`;

export const ProjectRolesHelpArticleLink = `${HELP_CENTER}/articles/24231784105745-Roles-and-Rights-at-Project-Level`;

export const OrgRolesHelpArticleLink = `${HELP_CENTER}/articles/24230905420945-Roles-and-Rights-at-Organization-level`;

export const BulkUploadPdfArticleLink = `${HELP_CENTER}/articles/23249630921361-How-to-bulk-upload-PDFs-for-articles`;

export const USER_GUIDE_PDF = "/sample-files/GES2024.pdf";

export const YOUTUBE_PAGE = "https://www.youtube.com/@easyslr";
